<template>
  <article class="content">
    <div class="container">
      <div class="container-title">
        <img src="@/assets/app-icon.png" width="75" height="75" alt="GolfVision icon" class="container-logo">
      </div>

      <div class="step">
        <h1 class="step-title">{{ $t('routes.password_reset.title')}}</h1>
        <p v-if="token_error" class="step-text">
          {{ $t(`routes.password_reset.errors.token_error.${token_error}`)}}
        </p>
        <el-form v-else :label-position="'top'" @submit.native.prevent :rules="form_rules" :model="password_form" ref="passwordForm" status-icon label-width="180px">
          <el-form-item :label="$t('routes.password_reset.new_password')" prop="new_password">
            <el-input type="password" v-model="password_form.new_password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('routes.password_reset.confirm_password')" prop="confirm_password">
            <el-input type="password" v-model="password_form.confirm_password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item style="margin-top: 60px;">
            <el-button type="primary" @click="submitNewPassword" :loading="submitting">{{ $t('submit') }}</el-button>
            <el-button @click="resetForm">{{ $t('reset') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </article>
</template>

<script>
  import apiClient from '@/api/client';
  import { Message, MessageBox } from 'element-ui';

  const MIN_PASSWORD_LENGTH = 8
  const MAX_PASSWORD_LENGTH = 128

  export default {
    data() {
      return {
        password_form: {
          reset_password_token: this.$route.query.reset_password_token || '',
          new_password: '',
          confirm_password: ''
        },
        form_rules: {
          new_password: [
            {
              required: true,
              message: this.$i18n.t('validation.required'),
              trigger: 'blur'
            },
            {
              min: MIN_PASSWORD_LENGTH,
              max: MAX_PASSWORD_LENGTH,
              message: this.$i18n.t('routes.password_reset.errors.password_length', { min: MIN_PASSWORD_LENGTH, max: MAX_PASSWORD_LENGTH }),
              trigger: 'blur'
            },
            {
              validator: this.newPasswordValidator,
              trigger: 'blur'
            }
          ],
          confirm_password: [
            {
              required: true,
              message: this.$i18n.t('validation.required'),
              trigger: 'blur'
            },
            {
              min: MIN_PASSWORD_LENGTH,
              max: MAX_PASSWORD_LENGTH,
              message: this.$i18n.t('routes.password_reset.errors.password_length', { min: MIN_PASSWORD_LENGTH, max: MAX_PASSWORD_LENGTH }),
              trigger: 'blur'
            },
            {
              validator: this.confirmPasswordValidator,
              trigger: 'blur'
            }
          ]
        },
        submitting: false
      }
    },

    computed: {
      token_error() {
        if (this.$route.query.token_error) {
          return this.$route.query.token_error;
        }
        if (!this.password_form.reset_password_token) {
          return 'invalid';
        }
        return '';
      }
    },

    methods: {
      newPasswordValidator(rule, value, callback) {
        if (this.password_form.confirm_password !== '' && value !== this.password_form.confirm_password) {
          callback(new Error(this.$i18n.t('routes.password_reset.errors.password_not_match')));
        } else {
          callback();
        }
      },
      confirmPasswordValidator(rule, value, callback) {
        if (value !== this.password_form.new_password) {
          callback(new Error(this.$i18n.t('routes.password_reset.errors.password_not_match')));
        } else {
          callback();
        }
      },
      submitNewPassword() {
        this.$refs['passwordForm'].validate(valid => {
          if (valid) {
            this.submitting = true;
            console.log(this.password_form.reset_password_token + '\n' + this.password_form.new_password + '\n' + this.password_form.confirm_password);
            apiClient.put('/auth/password', {
              reset_password_token: this.password_form.reset_password_token,
              password: this.password_form.new_password,
              password_confirmation: this.password_form.confirm_password
            })
            .then(() => {
              MessageBox({
                title: '',
                message: this.$i18n.t('routes.password_reset.success'),
                type: 'success',
                callback: () => {
                  this.$router.replace({ name: 'welcome' });
                }
              })

            })
            .catch(err => {
              if (err.response && err.response.data && err.response.data.error) {
                Message.error(this.$i18n.t('gv_cloud_error.code_' + err.response.data.error.code));
              } else {
                Message.error(this.$i18n.t('general_api_error'));
              }
              return false;
            })
            .finally(() => {
              this.submitting = false;
            });
          } else {
            return false;
          }
        });
      },
      resetForm() {
        this.$refs['passwordForm'].resetFields();
        this.submitting = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
.content {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 100%;

  @media screen and (max-width: 1000px) {
    padding-bottom: 0;
    background-color: #FAF7F4;
  }
}
.container {
  color: #006E50;
  border-radius: 4px;
  background-color: #FAF7F4;
  width: 940px;
  margin: 64px auto 0 auto;
  padding: 40px 160px 64px;

  &-title {
    font-size: 3.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-name {
      margin-top: 24px;
    }
  }

  &-text {
    margin: 20px 0;
    font-size: 2rem;
    line-height: 1.66;
  }

  &-text--invalid {
    text-align: center;
    margin-top: 60px;
  }

  .el-steps {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 24px auto 0 auto;
    padding: 0;
    &-title {
      width: 100%;
    }

    &-text {
      font-size: 1.2rem;
    }
  }

  @media screen and (max-width: 350px) {
    margin-top: 0;

    &-logo {
      width: 60px;
    }
  }
}

.step {
  &-title {
    color: #006E50;
    font-size: 3.6rem;
    text-align: center;
    margin: 40px 0;
  }

  &-text {
    color: #006E50;
    margin: 20px 0;
    font-size: 2.4rem;
    line-height: 1.66;
    text-align: center;
  }

  .el-form /deep/ {
    /*text-align: center;*/
    margin-top: 24px;
    .el-form-item__label {
      font-size: 1.8rem;

      @media screen and (max-width: 1000px) {
        font-size: 1.6rem;
      }

      &::before {
        display: none;
      }
    }
    .el-input__inner {
      font-size: 1.6rem;
      @media screen and (max-width: 1000px) {
        height: 40px;
        line-height: 40px;
        font-size: 1.4rem;
      }
      @media screen and (max-width: 350px) {
        font-size: 1.2rem;
      }
    }
    .el-form-item {
      width: 320px;
      margin: 0 auto 40px;
    }
    .el-form-item__content {
      width: 320px;
      margin: 0 auto 0 auto;
      text-align: center;
      @media screen and (max-width: 1000px) {
        width: 100%;
        margin: 0;
      }
    }

    .el-form-item__error {
      font-size: 1.4rem;
      padding-top: 10px;
      text-align: left;
      text-indent: -20px;
      padding-left: 20px;

      @media screen and (max-width: 1000px) {
        font-size: 1.2rem;
      }

      &::before {
        content: '*';
        color: #f56c6c;
      }
    }

    .el-button {
      font-size: 1.8rem;
      width: 140px;
      &--primary {
        background-color: #006E50;
        border-color: #006E50;
      }
    }
  }


  @media screen and (max-width: 1000px) {
    &-title {
      margin-top: 24px;
      font-size: 2.4rem;
    }

    &-text {
      font-size: 1.2rem;
    }

    .el-form {
      margin-top: 48px;
      text-align: left;
    }
  }

  @media screen and (max-width: 350px) {
    .el-form {
      margin-top: 16px;
    }

    &-hello {
      margin: 16px 0;
    }
  }
}
</style>

