<template>
  <article class="content">
    <div class="container">
      <div class="invitation-success">
        <img class="invitation-success-icon" src="@/assets/app-icon.png" width="75" height="75">

        <h1 class="invitation-success-title">{{ $t('routes.email_confirmation.title') }}</h1>
        <p v-if="confirm_result == 'already_confirmed'" class="container-text container-text--invalid">
          {{ $t('routes.email_confirmation.errors.already_confirmed') }}
        </p>
        <p v-else-if="confirm_result == 'confirmation_period_expired'" class="container-text container-text--invalid">
          {{ $t('routes.email_confirmation.errors.expired') }}
        </p>
        <p v-else class="container-text container-text--invalid">
          {{ $t('routes.email_confirmation.errors.invalid') }}
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
	data() {
		return {
			confirm_result: ''
		};
	},

  created() {
    const query = this.$route.query;
    if (query.account_confirmation_success) {
      this.$router.replace({ name: 'welcome' });
      return;
    } else if (query.confirmation_token) {
      this.confirm_result = query.confirmation_token;
    } else if (query.email) {
      this.confirm_result = query.email;
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  background-color: #FAF7F4;
  position: absolute;
  left: 0;
  right: 0;
  min-height: 100%;
  @media screen and (max-width: 1000px) {
    padding-bottom: 0;
  }
}

.container {
  background-color: #FAF7F4;
  border-radius: 4px;
  width: 940px;
  margin: 64px auto 0 auto;
  padding: 64px 160px;
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 24px auto 0 auto;
    padding: 0;
  }

  &-text {
    padding-bottom: 40px;
    font-size: 2.4rem;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 24px auto 0 auto;
    padding: 0;
    &-title {
      width: 100%;
    }

    &-text {
      font-size: 1.2rem;
    }
  }

}

.invitation-success {
  color: #006E50;
  margin-top: 100px;
  text-align: center;

  &-icon {
    margin-bottom: 64px;
  }

  &-title {
    font-size: 3.6rem;
    margin-bottom: 40px;
  }

  &-content {
    font-size: 1.8rem;

    & + & {
      margin-top: 152px;
    }
  }

  &-download {
    display: block;
    margin: 0 auto;
    width: 128px;
    margin-top: 20px;
  }

  &-footer {
    font-size: 1.6rem;
    margin: 40px -64px 0;
  }

  @media screen and (max-width: 1000px) {
    margin: 20px 0 100px;
    &-title {
      font-size: 2.4rem;
    }

    &-content {
      font-size: 1.2rem;

      & + & {
        margin-top: 80px;
      }
    }

    &-footer {
      font-size: 1.2rem;
      position: absolute;
      margin: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 24px;
      background-color: #f5f5f5;
    }
  }

  @media screen and (max-width: 350px) {
    &-content {
      & + & {
        margin-top: 24px;
      }
    }
  }
}

</style>