import axios from 'axios';
import https from 'https';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

if (process.env.NODE_ENV === 'development') {
  axios.defaults.httpsAgent = new https.Agent({
    rejectUnauthorized: false
  });
}

export default axios;
