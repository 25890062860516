<template>
  <article class="content">
    <div class="container">
      <div class="invitation-success">
        <img class="invitation-success-icon" src="@/assets/app-icon.png" width="75" height="75">

        <h1 class="invitation-success-title">Welcome onboard!</h1>

        <p class="invitation-success-content">
          Now you could open Golf Vision and login with this account.
        </p>

        <p class="invitation-success-download-hint">
          To download the latest version of Golf Vision
        </p>
        <a class="invitation-success-download" target="_blank" href="https://apps.apple.com/app/id1582271201?mt=8"><img src="@/assets/apple.png" width="128" height="38" alt="apple app store"></a>
        <p class="invitation-success-footer">
          * For more details about Golf Vision, please check <a target="_blank" href="https://www.golf-vision.com/">https://www.golf-vision.com/</a>
        </p>
      </div>
    </div>
  </article>
</template>

<script>
  export default {

  };
</script>

<style lang="scss" scoped>
.content {
  background-color: #FAF7F4;
  position: absolute;
  left: 0;
  right: 0;
  min-height: 100%;
  @media screen and (max-width: 1000px) {
    padding-bottom: 0;
  }
}

.container {
  color: #333;
  background-color: #FAF7F4;
  border-radius: 4px;
  width: 940px;
  margin: 0 auto 0 auto;
  padding: 64px 160px;
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 24px auto 0 auto;
    padding: 0;
  }
}

.invitation-success {
  margin-top: 64px;
  text-align: center;
  &-title {
    color: #006E50;
    font-size: 4.8rem;
  }

  &-content {
    color: #006E50;
    font-size: 1.8rem;

    & + & {
      margin-top: 152px;
    }
  }

  &-download-hint {
    color: #006E50;
    font-size: 1.6rem;
    margin-top: 152px;
  }

  &-icon {
    margin-bottom: 64px;
  }

  &-download {
    display: block;
    margin: 0 auto;
    width: 128px;
    margin-top: 20px;
    font-size: 1.4rem;
  }

  &-footer {
    font-size: 1.2rem;
    margin: 40px -64px 0;
  }

  @media screen and (max-width: 1000px) {
    margin: 20px 0 100px;
    &-title {
      font-size: 2.4rem;
    }

    &-content {
      font-size: 1.2rem;

      & + & {
        margin-top: 80px;
      }
    }

    &-download-hint {
      color: #006E50;
      font-size: 1.2rem;
      margin-top: 80px;
    }

    &-icon {
      margin-bottom: 32px;
    }

    &-footer {
      font-size: 1.0rem;
      position: absolute;
      margin: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 24px;
      background-color: #f5f5f5;
    }
  }

  @media screen and (max-width: 350px) {
    &-content {
      & + & {
        margin-top: 24px;
      }
    }
  }
}

</style>